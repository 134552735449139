const breakpointSm = window.matchMedia('(max-width: 767px)');
const breakpointMd = window.matchMedia('(min-width:1024px)');
const touchDevice = window.matchMedia('(pointer: coarse)').matches;

const getTextHeight = (mobileLineHeight = 24, desktopLineHeight = 24, mobileLines = 8, desktopLines = 4) => {
  if (breakpointSm.matches) {
    return mobileLineHeight * mobileLines;
  }
  return desktopLineHeight * desktopLines;
};

const setAccordionStartState = () => {
  const activeContent = document.querySelectorAll('.accordion__content.active');
  if (activeContent.length) {
    activeContent.forEach((el) => {
      el.style.transition = 'none';
      el.style.maxHeight = '100%';
      setTimeout(() => {
        el.style.transition = null;
        el.style.maxHeight = el.scrollHeight + 'px';
      }, 300);
    });
  }
};

const updateActiveAccordion = () => {
  const activeContent = document.querySelectorAll('.accordion__content.active');
  const accordions = document.querySelectorAll('.specification__description.accordion');
  if (activeContent.length) {
    activeContent.forEach((el) => {
      el.style.transition = 'none';
      el.style.maxHeight = el.scrollHeight + 'px';
      setTimeout(() => {
        el.style.transition = null;
      }, 300);
    });
  }

  if (accordions.length) {
    accordions.forEach((el) => {
      const btn = el.children[0];
      const content = el.children[1];
      if (content.scrollHeight <= getTextHeight(24, 32, el.dataset.textHeightMobile ? +el.dataset.textHeightMobile : 8, el.dataset.textHeightDesktop ? +el.dataset.textHeightDesktop : 4)) {
        content.style.maxHeight = 'none';
        btn.style.display = 'none';
      } else {
        content.style.maxHeight = null;
        btn.style.display = null;
      }
    });
  }
};

const initAccordionAction = (accordion) => {
  const btn = accordion.children[0];
  const content = accordion.children[1];
  const parent = accordion.closest('.accordion__content');
  btn.addEventListener('click', (e) => {
    if (!btn.closest('.main-nav__link') || touchDevice && breakpointMd) {
      e.preventDefault();
    }

    btn.blur();
    const maxHeight = content.style.maxHeight;
    if (maxHeight) {
      content.style.maxHeight = null;
      btn.classList.remove('active');
      content.classList.remove('active');
    } else {
      content.style.maxHeight = content.scrollHeight + 'px';
      btn.classList.add('active');
      content.classList.add('active');
    }
    if (parent) {
      parent.style.maxHeight = parent.scrollHeight + content.scrollHeight + 'px';
    }
  });
};

const initAccordion = () => {
  const accordions = document.querySelectorAll('.accordion');
  if (accordions.length) {
    setAccordionStartState();
    // window.addEventListener('resize', updateActiveAccordion);
    accordions.forEach((accordion) => initAccordionAction(accordion));
    updateActiveAccordion();
  }
};

window.initAccordion = initAccordion;

export {initAccordion};
